<template>
	<div>
		<div class="header">
			<div class="centerLayout">
				<div class="pt_header">
					<img class="logo" src="~@/assets/logo.png" alt="logo"/>
					<!--<img src="~@/assets/logoTxt.png" />-->
					<el-input placeholder="请输入内容" v-model="keys">
						<el-button style="padding-right:10px" slot="suffix" type="text"  @click="goSearch">搜索</el-button>
					</el-input>
					<div style="float:right;margin-top:40px">
						<login-box></login-box>
					</div>
				</div>
			</div>
		</div>
		<div class="home_menu">
			<div class="centerLayout">
				<ul class="home_menu_box">
					<li :class="$route.meta.nav=='home'?'is_active':''">
						<router-link to="/home">首页</router-link>
					</li>
					<li :class="$route.meta.nav=='article'?'is_active':''">
						<router-link to="/article">文章</router-link>
					</li>
					<li :class="$route.meta.nav=='cool'?'is_active':''">
						<router-link to="/cool">炫物</router-link>
					</li>
					<li :class="$route.meta.nav=='footprint'?'is_active':''">
						<router-link to="/footprint">足迹</router-link>
					</li>
					<li :class="$route.meta.nav=='activity'?'is_active':''">
						<router-link to="/activity">活动</router-link>
					</li>
					<li>
						<router-link to="/store">商城</router-link>
					</li>
          			<!-- <li>{{$route.meta}}</li> -->
				</ul>
			</div>
		</div>
		<div class="body-main">
			<router-view></router-view>
		</div>
		<div class="footer">
			<p>地址：北京市房山区城关街道顾八路1区1号-P188</p>
			<p>
				版权所有 北京鱼海科技有限公司
				<!-- <a href="http://www.beian.miit.gov.cn" target="_blank">京ICP备18052954号</a> -->
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">{{BAH}}</a>
			</p>
		</div>
	</div>
</template>
<script>
import server from '@/services/service'
import instance from '@/services/instance'
import { mapState, mapActions, mapMutations } from 'vuex'
import loginBox from '@/view/login/loginType'

export default {
	name: 'Home',
	data() {
		return {
			activeIndex: '/home',
			who: 'articleHome',
			loginImage: '',
			visible: false,
			timer: '',
			loginStatus: '',
            BAH:"",//备案号
			showUserLogin: false,
			user: {
				name: '',
				img: '',
			},
			keys: '',
		}
	},
    components: {
        loginBox,
    },
	watch: {
		// loginStatus(nVal, oVal) {
		// 	if (nVal == true) {
		// 		this.timer = setInterval(this.getLogin, 3000)
		// 	} else if (nVal == false) {
		// 		clearInterval(this.timer)
		// 	}
		// },
	},
	created() {
        let urlHost = window.location.host;
        let reg=/^[a-zA-Z]{2,4}(\.)/
        let url="";
        if(reg.test(urlHost)){
            let num=urlHost.indexOf(".");
            url=urlHost.slice(num+1,urlHost.length);
        }else{
            url=urlHost;
        }
        if(url=="cmstart.cn"){
            this.BAH="京ICP备18052954号-2";
        }else if(url=="yuyouqu.cn"){
            this.BAH="京ICP备18052954号-3";
        }else{
            this.BAH="京ICP备18052954号-2";
        }
    	this.get_user_info();
    	// this.getArtClassify("00002");
	},
	computed: {
		...mapState('user', ['token', 'user_name', 'user_photo']),
	},
	methods: {
		...mapActions('user', [
			'update_user_info',
			'remove_user_info',
			'get_user_info',
		]),
    getArtClassify(id){
      return new Promise((reslove,reject)=>{
        instance.post('/officialWebsiteMgr/resource/show_favor/classList',{pId:id}).then((res) => {
          if (res.code == 0) {
            reslove(res.data)
          }
          else{
            reject(res.msg)
          }
        })
      })
    },
        goSearch(){
            this.$router.push({
                name: 'Search',
                params: {
                    txt: this.keys,
                },
            })
        }
	},
}
</script>
<style lang="scss">
  @import "@/assets/css/index.scss";
  @import "@/assets/css/art.scss";
  @import "@/assets/css/cool.scss";
</style>
